import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Про нас | Відкрийте для себе гольф-клуб Fairway Dreams</title>
        <meta
          name={"description"}
          content={"Підвищуйте рівень гри з кожним відвідуванням!"}
        />
        <meta
          property={"og:title"}
          content={"Про нас | Відкрийте для себе гольф-клуб Fairway Dreams"}
        />
        <meta
          property={"og:description"}
          content={"Підвищуйте рівень гри з кожним відвідуванням!"}
        />
        <meta
          property={"og:image"}
          content={"https://start.novirexborn.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://start.novirexborn.com/img/strategy.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://start.novirexborn.com/img/strategy.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="140px 0"
        sm-padding="40px 0 40px 0"
        background="url(https://start.novirexborn.com/img/2.jpg) center/cover"
      >
        <Override slot="SectionContent" sm-align-items="center" />
        <Box
          max-width="560px"
          padding="50px 80px 80px 50px"
          background="--color-light"
          color="--dark"
        >
          <Text
            as="h4"
            font="--base"
            color="--grey"
            letter-spacing="1px"
            text-transform="uppercase"
            margin="6px 0"
          >
            Про нас
          </Text>
          <Text as="h2" font="--headline2" margin="0 0 12px 0">
            Наша суть
          </Text>
          <Text font="--base">
            Гольф-клуб Fairway Dreams перетворився з простого захоплення гольфом
            на процвітаючу спільноту, куди приходять ентузіасти з усіх верств
            суспільства, щоб поділитися своєю любов'ю до гри та відсвяткувати
            її. Заснований з метою створення гостинного та збагачуючого
            середовища, Fairway Dreams - це не просто місце для гри - це місце
            для розвитку, спілкування та створення незабутніх спогадів на
            зеленому полі.
          </Text>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Box
          margin="0px 0px 32px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        />
        <LinkBox
          display="flex"
          margin="0px 0px 50px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="40%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://start.novirexborn.com/img/3.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="60%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Наші поля
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Серце Fairway Dreams лежить на нашому чудово спроектованому полі
                для гольфу. Створене досвідченими дизайнерами, наше поле
                пропонує поєднання виклику та краси, де кожна лунка створена,
                щоб перевірити ваші навички та винагородити за точність. Наші
                зелені насадження, оточені природною красою, доглядаються з
                особливою ретельністю, що гарантує першокласний ігровий досвід
                кожного разу, коли ви виходите на поле.
              </Text>
            </Box>
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 50px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="60%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              lg-padding="0px 0px 0px 0px"
              sm-width="100%"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Що робить нас особливими?
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Цілісний підхід до гольфу: не просто грати, а вдосконалюватися і
                насолоджуватися кожним аспектом гри.
                <br />
                Екологічно чисті методи: Ми прагнемо до сталого розвитку,
                використовуючи екологічно чисті методи для підтримки нашого
                курсу.
                <br />
                Інноваційні заходи для членів клубу: Регулярні турніри,
                соціальні зустрічі та тематичні ігрові вечори створюють яскраву,
                цікаву спільноту.
                <br />
                Всепогодні умови для гри: У будь-яку погоду наші об'єкти
                обладнані для комфортної гри в гольф.
              </Text>
            </Box>
          </Box>
          <Box
            width="40%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://start.novirexborn.com/img/4.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://start.novirexborn.com/img/5.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              lg-padding="0px 0px 0px 0px"
              sm-width="100%"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Приєднуйтесь до нашої спадщини
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                У гольф-клубі Fairway Dreams ми віримо, що створюємо історії, а
                не просто граємо в ігри. Приєднуйтесь до нас, щоб розпочати
                власну главу в багатому гобелені нашої гольф-спільноти. Якщо ви
                прагнете вдосконалити свою гру, знайти нових друзів або просто
                насолодитися спокоєм поля для гольфу, Fairway Dreams - це те, що
                вам потрібно.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
